import React, { useEffect, useState } from 'react';

const PdfViewer = ({ path }) => {
  const [heightCalc, setHeightCalc] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setHeightCalc(400);
      } else {
        setHeightCalc(800);
      }
    };

    // Set initial zoom level
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex justify-center items-center">
      <embed 
        src={path} 
        width="100%" 
        height="600px" 
        type="application/pdf" 
      />
    </div>
  );
};

export default PdfViewer; 