import * as React from 'react';

// MUI Imports
import ResumeHeader from './ResumeTimeline/ResumeHeader';

// Import PDF
import PdfViewer from './ResumeTimeline/PdfViewer';
import resume from './ResumeTimeline/Paul_Storbergs_Resume.pdf';

export default function ResumeSection() {
    return (
      <>
        <ResumeHeader />
        <div className="flex justify-center items-center h-full w-full" style={{ minWidth: '-webkit-fill-available' }}>
          <PdfViewer path={resume} />
        </div>
      </>
    );
}
